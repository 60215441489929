/** @format */

import { useEffect } from 'react'
import { useTelegram } from './fns/useTelegram'
import RouterApp from './Components/Router'

function App() {
  const { expand, tg } = useTelegram()

  useEffect(() => {
    expand()
  }, [])

  return <RouterApp />
}

export default App
