import Truck from '../Truck/Truck'

export default function TruckPage({
  trucks = [],
  setActivePage,
  setSelectedTruck
}) {
  return (
    <div className="flex flex-col gap-4 py-3 px-4 overflow-y-scroll flex-1">
      {trucks
        .filter((item) => item.truck_id)
        .map((item) => (
          <Truck
            key={Math.random()}
            truckDataProps={item}
            setActivePage={setActivePage}
            setSelectedTruck={setSelectedTruck}
          />
        ))}
    </div>
  )
}
