import { Switch } from '@nextui-org/react'
import { sendDataUpdate } from '../../../fns/useTelegram'

const Section = ({ title, value }) => (
  <div className="flex flex-row pb-5 border-b-1 border-border-color justify-between">
    <div className="text-base text-black text-opacity-55 font-medium">
      {title}
    </div>
    {typeof value === 'string' ? (
      <div className="text-base text-black text-opacity-55 font-semibold capitalize">
        {value}
      </div>
    ) : (
      value
    )}
  </div>
)

export default function Detail({ selectedTruck }) {
  return (
    <div className="grid grid-cols-1 px-8 overflow-y-scroll flex-1 place-content-start mt-5 gap-5">
      <Section title={'Driver'} value={selectedTruck?.driver} />
      <Section
        title={'Border'}
        value={
          <Switch
            color="primary"
            classNames={{
              wrapper: 'mr-0 rounded-md w-16 bg-bg-switch-off',
              base: 'w-16',
              startContent: '!text-sm !font-bold',
              endContent: '!text-sm !font-bold',
              thumb: 'group-data-[selected=true]:ml-8'
            }}
            startContent={<div>ON</div>}
            endContent={<div>OFF</div>}
            onChange={(e) => {
              sendDataUpdate(
                {
                  truck: {
                    id: selectedTruck.truck.id,
                    border_access: e.target.checked ? 1 : 0
                  }
                },
                selectedTruck.truck.id
              )
            }}
            defaultSelected={!!selectedTruck?.truck?.border_access}
          />
        }
      />
      <Section
        title={'Citizen'}
        value={selectedTruck?.truck?.is_us_citizen == 0 ? 'No' : 'Yes'}
      />
      <Section
        title={'Truck Dimensions'}
        value={`${selectedTruck?.truck?.length}L x ${selectedTruck?.truck?.width}W x${selectedTruck?.truck?.height}H`}
      />
      <Section
        title={'Door Dimensions'}
        value={`${selectedTruck?.truck?.door_width}W x ${selectedTruck?.truck?.door_height}H`}
      />
      <Section title={'Weight'} value={`${selectedTruck?.truck?.weight} lbs`} />
      <Section title={'Identifier'} value={selectedTruck?.truck.identifier} />
      <Section
        title={'Plate Number'}
        value={selectedTruck?.truck.plate_number}
      />
      <Section title={'VIN Number'} value={selectedTruck?.truck.vin} />
      <Section title={'Model'} value={selectedTruck?.truck?.model} />
      <Section title={'Brand'} value={selectedTruck?.truck?.brand} />
      <Section title={'Year'} value={selectedTruck?.truck?.year + ''} />
      <Section title={'Type'} value={selectedTruck?.type.toLowerCase()} />
      <Section
        title={'Refrigerated'}
        value={selectedTruck?.truck?.is_refrigerated == 0 ? 'No' : 'Yes'}
      />
      <Section
        title={'Cargo Area Type'}
        value={
          selectedTruck?.truck?.cargo_area_type_id == 2
            ? 'Hard Box'
            : 'Curtainside'
        }
      />
      <Section
        title={'Lift Gate'}
        value={
          <Switch
            color="primary"
            classNames={{
              wrapper: 'mr-0 rounded-md w-16 bg-bg-switch-off',
              base: 'w-16',
              startContent: '!text-sm !font-bold',
              endContent: '!text-sm !font-bold',
              thumb: 'group-data-[selected=true]:ml-8'
            }}
            startContent={<div>ON</div>}
            endContent={<div>OFF</div>}
            onChange={(e) => {
              sendDataUpdate(
                {
                  truck: {
                    id: selectedTruck.truck.id,
                    lift_gate: e.target.checked ? 1 : 0
                  }
                },
                selectedTruck.truck.id
              )
            }}
            defaultSelected={!!selectedTruck?.truck?.lift_gate}
          />
        }
      />
    </div>
  )
}
