import { useEffect, useState } from 'react'
import Detail from './Detail/Detail'
import Prepare from './Prepare/Prepare'
import Title from './Title/Title'
import TruckPage from './TrucksPage/TruckPage'
import { useTelegram } from '../../fns/useTelegram'

export default function ClientPage() {
  const [states, setStates] = useState()
  const [trucks, setTrucks] = useState()
  const [activePage, setActivePage] = useState(0)
  const [selectedTruck, setSelectedTruck] = useState(null)

  const { tg } = useTelegram()

  const handleMapActive = (truckData, truck) => {
    if (truck.active === 0) {
      return 1
    }

    switch (truckData.upd_status.toLowerCase()) {
      case 'updated': {
        return 2
      }

      case 'disabled': {
        return 3
      }

      case 'not updated': {
        return 4
      }
    }

    return 0
  }

  const handleLoadTrucks = () => {
    const headers = new Headers()

    headers.append('accept', 'application/json')
    if (process.env.REACT_APP_ENV === 'production') {
      headers.append('telegramid', tg?.initDataUnsafe?.user?.id)
    }

    fetch(`${process.env.REACT_APP_HOST}/webapp/trucks`, {
      method: 'GET',
      headers: headers
    })
      .then((res) => res.json())
      .then(async (data) => {
        const states = data.pop()
        setStates(
          Object.keys(states).map((key) => ({ key, value: states[key] }))
        )

        data = data.sort((a, b) => {
          const pointA = handleMapActive(a, a.truck)

          const pointB = handleMapActive(b, b.truck)

          return -(pointA - pointB)
        })

        for (const item of data) {
          const headers = new Headers()

          headers.append('accept', 'application/json')
          if (process.env.REACT_APP_ENV === 'production') {
            headers.append('telegramid', tg?.initDataUnsafe?.user?.id)
          }

          const bids = await fetch(
            `${process.env.REACT_APP_HOST}/webapp/directions/bid_details/?truck_id=${item.truck_id}&loc_from=${item.loc_from}`,
            {
              method: 'GET',
              headers: headers
            }
          )
            .then((res) => res.json())
            .catch(() => ({ status: 'error', states: [] }))

          item.bids = bids.states
        }

        setTrucks(JSON.parse(JSON.stringify(data)))
        setSelectedTruck((pre) => {
          return JSON.parse(
            JSON.stringify(
              data.find((item) => item?.truck_id === pre?.truck_id) || null
            )
          )
        })
      })
      .catch((e) => {
        console.log('Error when get trucks:', e)
      })
  }

  useEffect(() => {
    handleLoadTrucks()
  }, [activePage])

  return (
    <div className="flex flex-col w-svw h-svh flex-1 bg-background">
      {activePage !== 1 && (
        <Title
          activePage={activePage}
          setActivePage={setActivePage}
          setSelectedTruck={setSelectedTruck}
          selectedTruck={selectedTruck}
        />
      )}
      {activePage == 0 ? (
        <TruckPage
          trucks={trucks}
          setActivePage={setActivePage}
          setSelectedTruck={setSelectedTruck}
        />
      ) : activePage == 1 ? (
        <>
          {selectedTruck && (
            <Prepare
              selectedTruck={selectedTruck}
              setActivePage={setActivePage}
              states={states}
            />
          )}
        </>
      ) : (
        <Detail selectedTruck={selectedTruck} />
      )}
    </div>
  )
}
