import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Switch
} from '@nextui-org/react'
import { sendDataUpdate } from '../../../fns/useTelegram'
import { useRef, useState } from 'react'

export default function Truck({
  truckDataProps,
  setActivePage,
  setSelectedTruck
}) {
  const { isOpen, onOpen, onOpenChange } = useDisclosure()
  const {
    isOpen: isOpenConfirm,
    onOpen: onOpenConfirm,
    onOpenChange: onOpenChangeConfirm
  } = useDisclosure()

  const switchRef = useRef()

  const [truckData, setTruckData] = useState(truckDataProps)

  const handleMapActive = (truck) => {
    if (truck.active === 0) {
      return {
        color: 'rgb(0, 0, 0, 0.5)',
        backgroundColor: 'rgba(182, 182, 184, 0.3)'
      }
    }

    switch (truckData.upd_status.toLowerCase()) {
      case 'updated': {
        return {
          color: 'rgb(36, 168, 65)',
          backgroundColor: 'rgba(36, 168, 65, 0.25)'
        }
      }

      case 'not updated': {
        return {
          color: 'rgb(225, 2, 2)',
          backgroundColor: 'rgba(225, 2, 2, 0.25)'
        }
      }

      case 'disabled': {
        return {
          color: 'rgb(225, 2, 2)',
          backgroundColor: 'rgba(225, 2, 2, 0.25)'
        }
      }
    }

    return {
      title: 'Unknown',
      color: 'rgb(225, 2, 2)',
      backgroundColor: 'rgba(225, 2, 2, 0.25)'
    }
  }

  return (
    <>
      <div className="flex flex-col rounded-md shadow-custom">
        <div
          onClick={() => {
            setSelectedTruck(JSON.parse(JSON.stringify(truckData)))
            setActivePage(1)
          }}
        >
          <div className="flex flex-row justify-between items-center py-3 px-4">
            <div
              className={
                'text-base font-bold text-black ' +
                (truckData.truck.active === 0 ? 'text-unactive' : '')
              }
            >
              {truckData?.driver || ''}
            </div>

            <div
              className={`bg-opacity-25 text-base font-bold px-3 py-1 rounded capitalize`}
              style={{
                backgroundColor: handleMapActive(truckData.truck)
                  .backgroundColor,
                color: handleMapActive(truckData.truck).color
              }}
            >
              {truckData.upd_status || ''}
            </div>
          </div>
          <hr />
          <div className="grid grid-cols-3 p-4 justify-between">
            <div className="flex flex-col items-center">
              <div className="text-sm font-semibold text-placeholder">
                TRUCK TYPE
              </div>
              <div
                className={
                  'text-sm font-bold text-black mt-2 capitalize text-center ' +
                  (truckData.truck.active === 0 ? 'text-unactive' : '')
                }
              >
                {truckData?.type.toLowerCase() || ''}
              </div>
            </div>
            <div className="flex flex-col items-center">
              <div className="text-sm font-semibold text-placeholder">
                PLATE
              </div>
              <div
                className={
                  'text-sm font-bold text-black mt-2 capitalize text-center ' +
                  (truckData.truck.active === 0 ? 'text-unactive' : '')
                }
              >
                {truckData?.truck?.plate_number || ''}
              </div>
            </div>
            <div className="flex flex-col items-center">
              <div className="text-sm font-semibold text-placeholder">
                LOCATION
              </div>
              <div
                className={
                  'text-sm font-bold text-black mt-2 capitalize text-center ' +
                  (truckData.truck.active === 0 ? 'text-unactive' : '')
                }
              >
                {truckData?.address.split(',').slice(1).join(', ') || ''}
              </div>
            </div>
          </div>
          <hr />
        </div>

        <div className="flex flex-row justify-between items-center py-3 px-4">
          <div
            className={
              'text-primary text-base font-extrabold cursor-pointer active:opacity-50 ' +
              (truckData.truck.active === 0 ? 'text-unactive' : '')
            }
            onClick={onOpen}
          >
            {Object.keys(truckData.bids).length}/Bids
          </div>
          <Switch
            defaultSelected={!!truckData.truck.active || false}
            color="primary"
            classNames={{
              wrapper: 'mr-0 rounded-md w-16 bg-bg-switch-off',
              base: 'w-16',
              startContent: '!text-sm !font-bold',
              endContent: '!text-sm !font-bold',
              thumb: 'group-data-[selected=true]:ml-8'
            }}
            startContent={<div>ON</div>}
            endContent={<div>OFF</div>}
            isSelected={!!truckData.truck.active || false}
            ref={switchRef}
            onChange={(e) => {
              if (e.target.checked) {
                setTruckData({
                  ...truckData,
                  truck: {
                    ...truckData.truck,
                    active: 1
                  }
                })

                sendDataUpdate(
                  {
                    truck: {
                      id: truckData.truck.id,
                      active: 1
                    }
                  },
                  truckData.truck.id
                ).catch((e) => {
                  console.log('Error update status:', e.message)
                })
              } else {
                onOpenConfirm()
              }
            }}
          />
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        placement="center"
        classNames={{
          wrapper: 'px-5',
          closeButton: 'mt-2',
          base: 'rounded-md'
        }}
        scrollBehavior={'inside'}
        hideCloseButton
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                <div className="text-lg font-bold text-black">Bids</div>
              </ModalHeader>
              <ModalBody>
                {Object.keys(truckData.bids).map((item) => (
                  <div
                    className="flex items-center justify-between pb-5 border-b-1 border-border-color"
                    key={item}
                  >
                    <div className="text-base text-black">{item}</div>
                    <div className="text-primary font-bold text-base">
                      Bids: {truckData.bids[item]}
                    </div>
                  </div>
                ))}
              </ModalBody>
              <ModalFooter className="justify-center">
                <Button
                  variant="bordered"
                  color="primary"
                  className="w-full rounded-md"
                  size="lg"
                  onClick={onClose}
                >
                  <div className="text-base font-semibold">Cancel</div>
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isOpenConfirm}
        onOpenChange={onOpenChangeConfirm}
        placement="center"
        classNames={{
          wrapper: 'px-5',
          closeButton: 'mt-2',
          base: 'rounded-md'
        }}
        closeButton={<></>}
        scrollBehavior={'inside'}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalBody className="text-center border-b-1">
                <div className="pt-9 pb-4">
                  <div className="text-lg">
                    Are you sure you want to disable{' '}
                    <span className="font-bold">
                      {truckData.truck.plate_number}
                    </span>{' '}
                    driven by{' '}
                    <span className="font-bold">{truckData.driver}</span> ?
                  </div>
                  <div className="text-lg mt-2">Please confirm to proceed.</div>
                </div>
              </ModalBody>
              <ModalFooter className="justify-center">
                <div
                  className="flex-[0.5] text-center active:opacity-50"
                  onClick={() => {
                    setTruckData({
                      ...truckData,
                      truck: {
                        ...truckData.truck,
                        active: 1
                      }
                    })
                  }}
                >
                  <div
                    className="text-base font-semibold text-unactive"
                    onClick={() => {
                      onClose()
                    }}
                  >
                    Cancel
                  </div>
                </div>
                <div
                  className="flex-[0.5] text-center active:opacity-50"
                  onClick={() => {
                    setTruckData({
                      ...truckData,
                      truck: {
                        ...truckData.truck,
                        active: 0
                      }
                    })

                    sendDataUpdate(
                      {
                        truck: {
                          id: truckData.truck.id,
                          active: 0
                        }
                      },
                      truckData.truck.id
                    ).catch((e) => {
                      console.log('Error update status:', e.message)
                    })

                    onClose()
                  }}
                >
                  <div className="text-base font-semibold text-superbusy">
                    Disable
                  </div>
                </div>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}
